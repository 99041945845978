import axios from 'axios'
import { CLUSTER_API } from '../config/main';

import { 
  PublicKey,
} from '@solana/web3.js';
import * as anchor from '@project-serum/anchor';
const METAPLEX = new PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s');

const getAccountInfo = async (token:String, pubkey: String) => {
  const result = await axios.post(CLUSTER_API as string, {
    "jsonrpc": "2.0",
    "id": 1,
    "method": "getAccountInfo",
    "params": [
      pubkey,
      {
        "encoding": "jsonParsed"
      }
    ]
  }, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      },
  })
  //console.log('Account info: ', result)
  return result.data;
}


const getTokenAccountByOwner = async (token:String, owner: String, mint: String) => {
  //console.log('getTokenAccountByOwner TOKEN: ', token)
  const result = await axios.post(CLUSTER_API as string, {
    "jsonrpc": "2.0",
    "id": 1,
    "method": "getTokenAccountsByOwner",
    "params": [
      owner,
      {
        "mint": mint
      },
      {
        "encoding": "jsonParsed"
      }
    ]
  }, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      },
  });
  //console.log('getTokenAccountsByOwner RESULT: ', result)
  return result.data;
}

const getRecentBlockHash = async (token:String) => {
  const result = await axios.post(CLUSTER_API as string, {
    "jsonrpc": "2.0",
    "id": 1,
    "method": "getRecentBlockhash",
  }, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      },
  });
  return result.data;
}

const getBlockTime = async (token:String, block: number) => {
  const result = await axios.post(CLUSTER_API as string, {
    "jsonrpc": "2.0",
      "id":1, 
      "method":"getBlockTime",
      "params":[block]}, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          },
      }
    );
  return result.data;
}

const getMetadata = async (mint: PublicKey): Promise<PublicKey> => {
  return (
      await PublicKey.findProgramAddress([Buffer.from('metadata'), METAPLEX.toBuffer(), mint.toBuffer()], METAPLEX)
  )[0];
};

const getMasterEdition = async (
  mint: anchor.web3.PublicKey
): Promise<anchor.web3.PublicKey> => {
    return (
        await anchor.web3.PublicKey.findProgramAddress(
        [
            Buffer.from("metadata"),
            METAPLEX.toBuffer(),
            mint.toBuffer(),
            Buffer.from("edition"),
        ],
        METAPLEX
        )
    )[0];
};

const findTokenRecordPda = async (
  mint: PublicKey,
  token: PublicKey
  ): Promise<anchor.web3.PublicKey> => {
    return (
      await anchor.web3.PublicKey.findProgramAddress(
        [
            Buffer.from("metadata"),
            METAPLEX.toBuffer(),
            mint.toBuffer(),
            Buffer.from("token_record"),
            token.toBuffer(),
        ],
        METAPLEX
      )
    )[0];
}

export {
  getAccountInfo,
  getTokenAccountByOwner,
  getRecentBlockHash,
  getBlockTime,
  getMasterEdition,
  getMetadata,
  findTokenRecordPda
}
