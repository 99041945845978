import { useState, useEffect } from 'react'
import * as anchor from '@project-serum/anchor';
import { useConnection, useWallet, useAnchorWallet  } from "@solana/wallet-adapter-react";
import {
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import { 
  PublicKey,
  SystemProgram,
  Keypair,
  SYSVAR_RENT_PUBKEY,
  Connection,
  SYSVAR_INSTRUCTIONS_PUBKEY,
	ComputeBudgetProgram,
} from '@solana/web3.js';

import { useToasts } from 'react-toast-notifications'
import { AccountLayout, TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID, Token } from "@solana/spl-token";
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'

import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import EggBox from "../../components/EggBox";
import ProgressBar from "../../components/ProgressBar";
import { SolanaClient, SolanaClientProps } from '../../helpers/sol';
import { 
  COMMITMENT,
  CLUSTER_API, 
  PROGRAM_ID,
  ARTE_TOKEN_VAULT,
  ARTE_TOKEN_MINT,
  POOL_SIGNER_SEEDS,
  ARTE_VAULT_SEEDS,
  INTERVAL,
  ARTPUNK_UPDATE_AUTHORITY,
  ARTPUNK_UPDATE_AUTHORITY_2,
  ACHIEVEMENT_UPDATE_AUTHORITY,
  TOTAL_ARTPUNK,
  TOTAL_ACHIEVEMENT,
  DECIMAL,
  DAYTIME,
  POOL_ACCOUNT,
  MAX_NFT_COUNT,
  POOL_SEEDS,
  POOL_DATA_SEEDS,
  OLD_POOL_SIGNER_SEEDS
} from '../../config/main.js';
import { IDL } from '../../constants/idl'
import './index.css';
import { findTokenRecordPda, getAccountInfo, getBlockTime, getMasterEdition, getMetadata, getRecentBlockHash, getTokenAccountByOwner } from '../../api/api';
import { sendTransactions } from '../../helpers/sol/connection';
import axios from "axios";
import SwiperCore, {
  Navigation,
  Pagination
} from 'swiper';
import { delay } from '../../utils/Helper';
import { TOKEN_METADATA_ID } from '../../constants/NativePrograms';

const TOKEN_AUTH_RULES_ID = new PublicKey("auth9SigNpDKz4sJJ1DfCTuZrZNSAgh9sFD3rboVmgg");
const MPL_DEFAULT_RULE_SET = new PublicKey("AdH2Utn6Fus15ZhtenW4hZBQnvtLgM1YCW2MfVp7pYS5");
const METAPLEX = new PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s');

// install Swiper modules
SwiperCore.use([Navigation, Pagination]);
async function getToken(): Promise<any>{
  const res = (
    await axios.get(
      "https://restore.arte-token.com/get_token_staking",
      // "http://localhost:8080/get_token_staking",
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
  )
  //console.log('Token fetched: ', res)
  return res.data.token;
}

function createConnection(token: string){
  let connection = new Connection(process.env.REACT_APP_RPC_NODE as string)
  // if (token === ''){
  // connection = new Connection("https://api.mainnet-beta.solana.com", {"commitment": "confirmed", "httpHeaders": {"Authorization": `Bearer ${token}`,"Content-Type": "application/json"}})
  // } else {
  // connection = new Connection(process.env.REACT_APP_RPC_NODE as string, {"commitment": "confirmed", "httpHeaders": {"Authorization": `Bearer ${token}`,"Content-Type": "application/json"}})
  // }
  //console.log('New connection created', connection, token)
  return connection;
}

function HomePage() {
  const walletState = useWallet();
  const wallet = useAnchorWallet();
  const artpunksMints = require('../../mints_artpunks_final.json');

  const { addToast } = useToasts()

  const [token, setToken] = useState('')
  // const [tokenBool, setTokenBool] = useState(true)
  const connection = createConnection(token);
  const solanaClient = new SolanaClient(connection);

  const [artPunks, setArtPunks] = useState([
    {
      image: '',
      mint: '',
      tokenAccount: '',
      name: '',
      token_type: -1,
      attribute: -1
    },
  ]);
  const [achievements, setAchievements] = useState([
    {
      image: '',
      mint: '',
      tokenAccount: '',
      name: '',
      token_type: -1,
      attribute: -1
    },
  ]);

  const [claimArtPunks, setClaimArtPunks] = useState([
    {
      image: '',
      mint: '',
      tokenAccount: '',
      name: '',
      attribute: -1,
      token_type: -1,
      current: 0
    },
  ]);
  const [claimAchievements, setClaimAchievements] = useState([
    {
      image: '',
      mint: '',
      tokenAccount: '',
      name: '',
      attribute: -1,
      token_type: -1,
      current: 0
    },
  ]);

  const [loading, setLoading] = useState(-1);
  const [loadingText, setLoadingText] = useState('Claiming...');
  const [arteCount, setArteCount] = useState(0)
  const [arteCurrent, setArteCurent] = useState(0)
  const [totalStakedArtpunk, setTotalStakedArtpunk] = useState(0);
  const [totalStakedAchievement, setTotalStakedAchievement] = useState(0);
  const [intervalId, setIntervalId] = useState(0);

  useEffect(() => {
    try {
    (async () => {
      if (!walletState.connected) return;
      setArtPunks([]);
      setAchievements([]);
      setClaimArtPunks([]);
      setClaimAchievements([]);
      await reload();
    })()
  } catch (error){
    setLoading(-1);
    //console.log('Error during refresh...', error)
  }
  }, [walletState.connected]);

  // useEffect( () => {
  //   try {
  //     if (tokenBool) {
  //   (async () => {
  //     //console.log('Token fetched!')
  //   const token = await getToken();
  //   setTokenBool(false)
  //   setToken(token)
  //   })()
  // }
  // } catch (error) {
  //   setLoading(-1);
  //   //console.log('Error during refresh...', error)
  // }
  // }, [token]);

  const getProvider = () => {
    if (wallet)
		  return new anchor.Provider(solanaClient.connection, wallet, COMMITMENT as anchor.web3.ConfirmOptions);
	}

  const reload = async () => {
    try {
    if (walletState.connected) {
      setLoading(0);
      setLoadingText('Loading...')
      //For staking 
      const pubKey = walletState.publicKey?.toString() || '';
      if (!walletState.publicKey) {
        return;
      }

      let [pool_data, _nonce_data] = await anchor.web3.PublicKey.findProgramAddress(
        [Buffer.from(POOL_DATA_SEEDS)],
        new PublicKey(PROGRAM_ID)
      );

      let [pool_signer, _nonce_signer] = await anchor.web3.PublicKey.findProgramAddress(
        [Buffer.from(POOL_SIGNER_SEEDS), walletState.publicKey.toBuffer()],
        new PublicKey(PROGRAM_ID)
      );

      let walletAchievements: any[] = [];
      let walletArtpunks: any[] = [];
      let poolAchievements: any[] = [];
      let poolArtpunks: any[] = [];
      let result = await solanaClient.getAllCollectibles([pubKey], [
        { updateAuthority: ARTPUNK_UPDATE_AUTHORITY, collectionName: 'ArtPunk' } ,
        { updateAuthority: ARTPUNK_UPDATE_AUTHORITY_2, collectionName: 'ArtPunk' } ,
        { updateAuthority: ACHIEVEMENT_UPDATE_AUTHORITY, collectionName: '' },
      ]);
      if (result[pubKey]) {
        result[pubKey].forEach((res: any) => {
          //console.log(res.mint)
          if (artpunksMints.includes(res.mint) && res.name.indexOf('ArtPunk') >= 0) {
            let level = res?.attributes?.find((attr: any) => attr.trait_type === 'Restoration Grade');
            let value = 0;
            switch(level?.value) {
              case 'None':
                value = 0;
                break;
              case 'Standard':
                value = 1;
                break;
              case 'Excellent':
                value = 2;
                break;
              default:
                break;
            }
            walletArtpunks.push({
              ...res,
              token_type: 0,
              attribute: value,
              current: 0
            });
          }

          if (res.updateAuthority == ACHIEVEMENT_UPDATE_AUTHORITY) {
            let type = res?.attributes?.find((attr: any) => attr.trait_type === 'Achievement type');
            let value = 0;
            switch(type?.value) {
              case 'Metal':
                value = 0;
                break;
              case 'Bronze':
                value = 1;
                break;
              case 'Silver':
                value = 2;
                break;
              case 'Gold':
                value = 3;
                break;
              case 'Diamond':
                value = 4;
                break;
              default:
                break;
            }
            walletAchievements.push({
              ...res,
              token_type: 1,
              attribute: value,
              current: 0
            });
          }
        })
        
      }
      result = await solanaClient.getAllCollectibles([pool_signer.toString()], [
        { updateAuthority: ARTPUNK_UPDATE_AUTHORITY, collectionName: 'ArtPunk' } ,
        { updateAuthority: ARTPUNK_UPDATE_AUTHORITY_2, collectionName: 'ArtPunk' } ,
        { updateAuthority: ACHIEVEMENT_UPDATE_AUTHORITY, collectionName: '' },
      ]);
      if (result[pool_signer.toString()]) {
        result[pool_signer.toString()].forEach((res: any) => {
          if (artpunksMints.includes(res.mint) && res.name.indexOf('ArtPunk') >= 0) {
            let level = res?.attributes?.find((attr: any) => attr.trait_type === 'Restoration Grade');
            let value = 0;
            switch(level?.value) {
              case 'None':
                value = 0;
                break;
              case 'Standard':
                value = 1;
                break;
              case 'Excellent':
                value = 2;
                break;
              default:
                break;
            }
            poolArtpunks.push({
              ...res,
              token_type: 0,
              attribute: value,
              current: 0
            });
          }

          if (res.updateAuthority === ACHIEVEMENT_UPDATE_AUTHORITY) {
            let type = res?.attributes?.find((attr: any) => attr.trait_type === 'Achievement type');
            let value = 0;
            switch(type?.value) {
              case 'Metal':
                value = 0;
                break;
              case 'Bronze':
                value = 1;
                break;
              case 'Silver':
                value = 2;
                break;
              case 'Gold':
                value = 3;
                break;
              case 'Diamond':
                value = 4;
                break;
              default:
                break;
            }
            poolAchievements.push({
              ...res,
              token_type: 1,
              attribute: value,
              current: 0
            });
          }
        })
        await updateCurrentRewards(poolArtpunks, poolAchievements);
      }

      const provider = getProvider();
		  const program = new anchor.Program(IDL, new PublicKey(PROGRAM_ID), provider);
      const data = await program.account.data.fetch(pool_data);
      setArtPunks([...walletArtpunks]);
      setAchievements([...walletAchievements]);
      setClaimArtPunks([...poolArtpunks]);
      setClaimAchievements([...poolAchievements]);
      setTotalStakedArtpunk(data.artpunk);
      setTotalStakedAchievement(data.achievement);
      if (walletState.publicKey) {
        getTokenAccountByOwner(token, walletState.publicKey?.toString(), ARTE_TOKEN_MINT).then(result => {
          try {
          //console.log('Result: ', result)
          const { value } = result.result;
          if (value.length > 0) {
            let total_arte = 0;
            value.forEach((v: any) => {
              total_arte += v.account?.data?.parsed?.info?.tokenAmount?.uiAmount;
            })
            setArteCount(total_arte);
          }
        } catch (error ){
         // console.log('Error during fetching ARTE price')
        }
        })
      }
      await getArteToken();
      
      setLoading(-1);
      window.clearInterval(intervalId);
      const interval = window.setInterval(async() => {
        await updateCurrentRewards(poolArtpunks, poolAchievements);
      }, INTERVAL);
      setIntervalId(interval);
    }
  } catch (error) {
    setLoading(-1);
    console.log('Error during refresh...', error)
  }
} 

  const updateCurrentRewards = async (artpunkList?: any[], achievementList?: any[]) => {
    try {
      let currentSum = 0;
      let newClaimedArtpunks: any[] = artpunkList || claimArtPunks, newClaimedAchievements: any[] = achievementList || claimAchievements;
      let blockhash = await getRecentBlockHash(token);
      let currentTime = await getBlockTime(token, blockhash.result.context.slot);
      const provider = getProvider();
		  const program = new anchor.Program(IDL, new PublicKey(PROGRAM_ID), provider);

      let [pool_data, _nonce_data] = await anchor.web3.PublicKey.findProgramAddress(
        [Buffer.from(POOL_DATA_SEEDS)],
        new PublicKey(PROGRAM_ID)
      );

      for (let i = 0; i < newClaimedArtpunks.length; i ++) {
        let [pool, _nonce] = await anchor.web3.PublicKey.findProgramAddress(
          [Buffer.from(POOL_SEEDS), walletState.publicKey!.toBuffer(), new PublicKey(newClaimedArtpunks[i].mint).toBuffer()],
          new PublicKey(PROGRAM_ID)
        );

        const poolNft = await program.account.pool.fetch(pool)
        let current = 0;
        if (poolNft)
          current = poolNft?.reward.toNumber() * (currentTime.result - poolNft?.lastTime) / DECIMAL / DAYTIME;
        if (poolNft) {
          newClaimedArtpunks[i] = {
            ...newClaimedArtpunks[i],
            current: current < 0 ? 0 : current
          }
        }
        currentSum = currentSum + current;
      }
  
      for (let i = 0; i < newClaimedAchievements.length; i ++) {
        let [pool, _nonce] = await anchor.web3.PublicKey.findProgramAddress(
          [Buffer.from(POOL_SEEDS), walletState.publicKey!.toBuffer(), new PublicKey(newClaimedAchievements[i].mint).toBuffer()],
          new PublicKey(PROGRAM_ID)
        );

        const poolNft = await program.account.pool.fetch(pool)
        let current = 0;
        if (poolNft)
          current = poolNft?.reward.toNumber() * (currentTime.result - poolNft?.lastTime) / DECIMAL / DAYTIME;
        if (poolNft) {
          newClaimedAchievements[i] = {
            ...newClaimedAchievements[i],
            current: current < 0 ? 0 : current
          }
        }
        currentSum = currentSum + current;
      }
      const data = await program.account.data.fetch(pool_data);
      setClaimArtPunks([...newClaimedArtpunks]);
      setClaimAchievements([...newClaimedAchievements]);
      setTotalStakedArtpunk(data.artpunk);
      setTotalStakedAchievement(data.achievement);
      setArteCurent(currentSum);
    }
    catch (error) {
     // console.log('error', error);
    }
  }
  
  const getAttributeName = (token_type: number, attribute: number) => {
    if (token_type === 0) {
      switch(attribute) {
        case 0:
          return 'None';
        case 1:
          return 'Standard';
        case 2:
          return 'Excellent';
        default:
          break;
      }
    }
    else if (token_type === 1) {
      switch(attribute) {
        case 0:
          return 'Metal';
        case 1:
          return 'Bronze';
        case 2:
          return 'Silver';
        case 3:
          return 'Gold';
        case 4:
          return 'Diamond';
        default:
          break;
      }
    }
  }
  
  const getRewardFromAttribute = (token_type: number, attribute: number) => {
    if (token_type === 0) {
      switch(attribute) {
        case 0: return 5;
        case 1: return 10;
        case 2: return 20;
      }
    }
    else if (token_type === 1) {
      switch(attribute) {
        case 0: return 0.05;
        case 1: return 0.25;
        case 2: return 1;
        case 3: return 5;
        case 4: return 20;
      }
    }
    return 0;
  }

  const stake = async (nft: any) => {
    setLoadingText('Staking...');
    setLoading(1);
		const provider = getProvider();
		const program = new anchor.Program(IDL, new PublicKey(PROGRAM_ID), provider);
    try {
      if (!walletState.publicKey) {
        addToast("Connect your wallet!", {
          appearance: 'warning',
          autoDismiss: true,
        })
        setLoading(-1)
        return;
      }
      let instructionSet= [], signerSet = [];
      let [poolSigner, nonce_signer] = await anchor.web3.PublicKey.findProgramAddress(
        [Buffer.from(POOL_SIGNER_SEEDS), walletState.publicKey.toBuffer()],
          program.programId
      );

      const poolSignerInfo:any = await getAccountInfo(token, poolSigner.toString());
      if (!poolSignerInfo.result.value) {
        const instruction = await program.rpc.createPoolSigner(nonce_signer, {
          accounts: {
            poolSigner: poolSigner,
            user: walletState.publicKey,
            systemProgram: SystemProgram.programId
          }
        })
        instructionSet.push([instruction]);
        signerSet.push([]);
      }
      if (!nft) {
        let nfts: any[] = [];
        artPunks.forEach(art => {
          nfts.push({
            ...art,
          })
        })
        achievements.forEach(achive => {
          nfts.push({
            ...achive,
          })
        })
        for (let i = 0; i < nfts.length; i ++) {
          let [pool, nonce_pool] = await anchor.web3.PublicKey.findProgramAddress(
            [Buffer.from(POOL_SEEDS), walletState.publicKey.toBuffer(), new PublicKey(nfts[i].mint).toBuffer()],
              program.programId
          );
          const poolInfo = await getAccountInfo(token, pool.toString());
          if (!poolInfo.result.value) {
            const instruction = await program.instruction.createPool(nonce_pool, {
              accounts: {
                pool: pool,
                user: walletState.publicKey,
                mint: new PublicKey(nfts[i].mint),
                systemProgram: SystemProgram.programId
              }
            })
            instructionSet.push([instruction]);
            signerSet.push([]);
          }
          let newTx: any = await makeTransaction(program, poolSigner, pool, nfts[i]);
          instructionSet.push(newTx.transaction);
          signerSet.push(newTx.signers);
        }
        await sendTransactions(solanaClient.connection, walletState, instructionSet, signerSet)
        await delay(3000);
        await reload();
      }
      else {
        let [pool, nonce_pool] = await anchor.web3.PublicKey.findProgramAddress(
          [Buffer.from(POOL_SEEDS), walletState.publicKey.toBuffer(), new PublicKey(nft.mint).toBuffer()],
            program.programId
        );
        const poolInfo = await getAccountInfo(token, pool.toString());
        if (!poolInfo.result.value) {
          const instruction = await program.instruction.createPool(nonce_pool, {
            accounts: {
              pool: pool,
              user: walletState.publicKey,
              mint: new PublicKey(nft.mint),
              systemProgram: SystemProgram.programId
            }
          })
          instructionSet.push([instruction]);
          signerSet.push([]);
        }

        let newTx:any = await makeTransaction(program, poolSigner, pool, nft);
        instructionSet.push(newTx.transaction);
        signerSet.push(newTx.signers);
        await sendTransactions(solanaClient.connection, walletState, instructionSet, signerSet);
        
        await delay(3000);
        await reload();
      }
      setLoading(-1);
      addToast("Staking success!", {
        appearance: 'success',
        autoDismiss: true,
      })
    }
    catch (error) {
     // console.log('error', error);
      addToast("Staking failed!", {
        appearance: 'error',
        autoDismiss: true,
      })
      setLoading(-1)
    }
  }

  const claim = async (nft: any, mode: number, token:string) => {
    try {
      //console.log('Token value;', token)
      if (mode === 0) {
        setLoadingText('Claiming...');
      }
      else if (mode === 1) {
        setLoadingText('Unstaking...');
      }
      setLoading(1);
      if (!walletState.publicKey) {
        addToast("Connect your wallet!", {
          appearance: 'warning',
          autoDismiss: true,
        })
        return;
      }
      const provider = getProvider();
      const program = new anchor.Program(IDL, new PublicKey(PROGRAM_ID), provider);
      let [poolSigner, _nonceSigner] = await anchor.web3.PublicKey.findProgramAddress(
        [Buffer.from(POOL_SIGNER_SEEDS), walletState.publicKey.toBuffer()],
        program.programId
      );
  
      let [vault, _nonceVault] = await anchor.web3.PublicKey.findProgramAddress(
        [Buffer.from(ARTE_VAULT_SEEDS)],
          program.programId
      );

      let [poolData, _nonceData] = await anchor.web3.PublicKey.findProgramAddress(
        [Buffer.from(POOL_DATA_SEEDS)],
          program.programId
      );
      //console.log(poolSigner.toBase58(), vault.toBase58(), poolData.toBase58())
      let instructionSet = [];
      let signerSet = [];
      let transaction = [];
      let signers = [];
      let tokenResult = await getTokenAccountByOwner(token, walletState.publicKey.toString(), ARTE_TOKEN_MINT);
      //console.log('getTokenAccountByOwner', tokenResult, walletState.publicKey.toString())
      if (tokenResult.result.value.err) {
        addToast(`${mode === 1 ? 'Unstaking failed!' : 'Claiming failed!'}`, {
          appearance: 'error',
          autoDismiss: true,
        })
        setLoading(-1)
        return;
      }
      let token_to = '';
      let claimedDrakes: any[] = [];
      if (tokenResult.result.value.length === 0) {
        const aTokenAccount = new Keypair();
        const aTokenAccountRent = await solanaClient.connection.getMinimumBalanceForRentExemption(
          AccountLayout.span
        )
        transaction.push(SystemProgram.createAccount({
            fromPubkey: walletState.publicKey,
            newAccountPubkey: aTokenAccount.publicKey,
            lamports: aTokenAccountRent,
            space: AccountLayout.span,
            programId: TOKEN_PROGRAM_ID
        }));
        transaction.push(
          Token.createInitAccountInstruction(
            TOKEN_PROGRAM_ID,
            new PublicKey(ARTE_TOKEN_MINT),
            aTokenAccount.publicKey,
            walletState.publicKey
        ));
        signers.push(aTokenAccount);
        instructionSet.push(transaction);
        signerSet.push(signers);
        token_to = aTokenAccount.publicKey.toString();
      }
      else {
        token_to = tokenResult.result.value[0].pubkey;
      }

      const makeTransaction = async (nft: any, mode: number) => {
        let [pool, _noncePool] = await anchor.web3.PublicKey.findProgramAddress(
          [Buffer.from(POOL_SEEDS), walletState.publicKey!.toBuffer(), new PublicKey(nft.mint).toBuffer()],
          program.programId
        );

        const transaction = [], signers = [];
        let nftTo;
        let tokenResult = await getTokenAccountByOwner(token, walletState.publicKey!.toString(), nft.mint);
        if (tokenResult.result.value.length === 0) {
          const aTokenAccount = new Keypair();
          const aTokenAccountRent = await solanaClient.connection.getMinimumBalanceForRentExemption(
            AccountLayout.span
          )
          transaction.push(SystemProgram.createAccount({
              fromPubkey: walletState.publicKey!,
              newAccountPubkey: aTokenAccount.publicKey,
              lamports: aTokenAccountRent,
              space: AccountLayout.span,
              programId: TOKEN_PROGRAM_ID
          }));
          transaction.push(
            Token.createInitAccountInstruction(
              TOKEN_PROGRAM_ID,
              new PublicKey(nft.mint),
              aTokenAccount.publicKey,
              walletState.publicKey!
          ));
          signers.push(aTokenAccount);
          nftTo = aTokenAccount.publicKey.toString();
        }
        else {
          nftTo = tokenResult.result.value[0].pubkey;
        }

        if (mode === 0) {
          transaction.push(
            program.instruction.claim(_nonceVault, {
              accounts: {
                pool: pool,
                user: walletState.publicKey!,
                mint: new PublicKey(nft.mint),
                vault: vault,
                tokenFrom: new PublicKey(ARTE_TOKEN_VAULT),
                tokenTo: new PublicKey(token_to),
                tokenProgram: TOKEN_PROGRAM_ID
            }
          }));
        }
        else if (mode === 1) {
          //console.log(_nonceSigner, _nonceVault, pool.toString(), poolSigner.toString(), vault.toString(), poolData.toString(), TOKEN_METADATA_ID)
          console.log("mint: ", nft.mint);
          console.log("poolSigner: ", poolSigner.toBase58());
          console.log("nonceSigner: ", _nonceSigner);
          
          const mint = new PublicKey(nft.mint);
          const mintMetadata = await getMetadata(mint);
          const nftEdition = await getMasterEdition(mint);

          const tokenMintRecord = await findTokenRecordPda(mint, new PublicKey(nft.tokenAccount));
          const userTokenRecord = await findTokenRecordPda(mint, new PublicKey(nftTo));
          
          const modifyComputeUnits = ComputeBudgetProgram.setComputeUnitLimit({ 
            units: 2000000 
          });
          
          const addPriorityFee = ComputeBudgetProgram.setComputeUnitPrice({ 
            microLamports: 1 
          });
          transaction.push(modifyComputeUnits);
          transaction.push(addPriorityFee);

          transaction.push(
            program.instruction.unlockPnft(_nonceSigner, _nonceVault, {
              accounts: {
                pool: pool,
                poolSigner: poolSigner,
                user: walletState.publicKey!,
                mint: mint,
                vault: vault,
                data: poolData,
                nftFrom: new PublicKey(nft.tokenAccount),
                nftTo: new PublicKey(nftTo),
                // tokenFrom: new PublicKey(ARTE_TOKEN_VAULT),
                // tokenTo: new PublicKey(token_to),
                tokenProgram: TOKEN_PROGRAM_ID,
                mintMetadata,
                mintEdition: nftEdition,
                poolTokenRecord: tokenMintRecord,
                userTokenRecord: userTokenRecord,
                authorizationRules: MPL_DEFAULT_RULE_SET,
                mplTokenAuthRulesProgram: TOKEN_AUTH_RULES_ID,
                tokenMetadataProgram: METAPLEX,
                tokenAtaProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
                systemProgram: SystemProgram.programId,
                sysvarInstruction: SYSVAR_INSTRUCTIONS_PUBKEY
            }
          }));
        }
        return { transaction, signers};
      }
      if (!nft) {
        let nfts: any[] = [];
        claimArtPunks.forEach(art => {
          nfts.push({
            ...art,
            token_type: 0
          })
        })
        claimAchievements.forEach(achive => {
          nfts.push({
            ...achive,
            token_type: 1
          })
        })
        for (let i = 0; i < nfts.length; i ++) {
          const { transaction, signers } = await makeTransaction(nfts[i], mode); 
          instructionSet.push(transaction);
          signerSet.push(signers);
          claimedDrakes.push(i);
        }
        await sendTransactions(solanaClient.connection, walletState, instructionSet, signerSet);
        if (mode === 0) {
          setClaimArtPunks(claimArtPunks.map(artpunk => {
            return {
              ...artpunk,
              current: 0
            }
          }))
          setClaimAchievements(claimAchievements.map(achivement => {
            return {
              ...achivement,
              current: 0
            }
          }))
        }
       
      }
      else {
        const { transaction, signers } = await makeTransaction(nft, mode); 
        instructionSet.push(transaction);
        signerSet.push(signers);
        await sendTransactions(solanaClient.connection, walletState, instructionSet, signerSet);
        if (mode === 0) {
          setClaimArtPunks(claimArtPunks.map(artpunk => {
            if (artpunk.mint == nft.mint) {
              return {
                ...artpunk,
                current: 0
              }
            }
            else {
              return {
                ...artpunk
              }
            }
          }))

          setClaimAchievements(claimAchievements.map(achievement => {
            if (achievement.mint == nft.mint) {
              return {
                ...achievement,
                current: 0
              }
            }
            else {
              return {
                ...achievement
              }
            }
          }))
        }  
      }

      if (mode === 1) {
        await delay(3000);
        await reload();
      }
      else {
        await getArteToken();
      }
      setLoading(-1);
      addToast(`${mode === 1 ? 'Unstaking success!' : 'Claming success!'}`, {
        appearance: 'success',
        autoDismiss: true,
      })
    }
    catch (error) {
      //console.log('error', error);
      addToast(`${mode === 1 ? 'Unstaking failed!' : 'Claiming failed!'}`, {
        appearance: 'error',
        autoDismiss: true,
      })
      setLoading(-1)
    }
  }

  const makeTransaction = async (program: anchor.Program<any>, poolSigner: PublicKey, pool: PublicKey, nft: any) => {
    const aTokenAccount = new Keypair();
    const aTokenAccountRent = await solanaClient.connection.getMinimumBalanceForRentExemption(
      AccountLayout.span
    )
    let transaction = [];
    let signers:any[] = [];
    if (!walletState.publicKey)
      return;

    let [poolData, _nonceData] = await anchor.web3.PublicKey.findProgramAddress(
      [Buffer.from(POOL_DATA_SEEDS)],
        program.programId
    );

    transaction.push(SystemProgram.createAccount({
      fromPubkey: walletState.publicKey,
      newAccountPubkey: aTokenAccount.publicKey,
      lamports: aTokenAccountRent,
      space: AccountLayout.span,
      programId: TOKEN_PROGRAM_ID
    }));
    transaction.push(Token.createInitAccountInstruction(
      TOKEN_PROGRAM_ID,
      new PublicKey(nft.mint),
      aTokenAccount.publicKey,
      poolSigner
    ));
    
    signers.push(aTokenAccount);
    
    transaction.push(program.instruction.stake(nft.token_type, nft.attribute, {
      accounts: {
        user: walletState.publicKey,
        mint: nft.mint,
        pool: pool,
        data: poolData,
        from: new PublicKey(nft.tokenAccount),
        to: aTokenAccount.publicKey,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId, 
        rent: SYSVAR_RENT_PUBKEY
      },
      signers
    }));
    return { transaction, signers };
  }

  const getArteToken = async () => {
    try {
    if (walletState.publicKey) {
      const result: any = await getTokenAccountByOwner(token, walletState.publicKey?.toString(), ARTE_TOKEN_MINT);
      const { value } = result.result;
      if (value.length > 0) {
        let total_arte = 0;
        value.forEach((v: any) => {
          total_arte += v.account?.data?.parsed?.info?.tokenAmount?.uiAmount;
        })
        setArteCount(total_arte);
      }
    }
  } catch (error){
    //console.log('Error during fetching ARTE price')
  }
  }

  const stakeAll = async () => {
    await stake(null);
  }

  const claimAll = async () => {
    await claim(null, 0, token);
  }

  const unstakeAll = async () => {
    await claim(null, 1, token);
  }

  const getLength = (nftArray : any[]) => {
    //console.log(nftArray)
    if(nftArray.length === 0 || (nftArray.length < 2 && nftArray[0].tokenAccount==="")){
      return 0;
    } else {
      return nftArray.length
    }
  }
  
  return (
    <div className="div">
      {
        loading >= 0 ?
          <div id="preloader"> 
            { loading === 1 && <div style={{paddingTop: '150px', fontSize: '50px'}}>{loadingText}</div>}
          </div> :
          <div id="preloader" style={{display: 'none'}}></div>
      }
      <div className="home">
          <Header />
          <div className="status">
              <div className="font_48">
                {(claimArtPunks.length > 0 || claimAchievements.length > 0) && <>
                  <p>{ `ArtPunks' staking contract was hacked on 03/31/2023 - The hacker drained the whole $ARTE staking pool and sold it. 
The staking contract was fixed by our team to allow holders to unstake their ArtPunks, please do it as soon as possible. 
More details on our Discord and Twitter.`}</p></>
                } 
                {(claimArtPunks.length < 1 && claimAchievements.length < 1 ) && <>
                  <p>{ `Ready to`}</p>
                  <p>{ `Stake?`}</p></>
                }

              </div>
              <div className="btn_group ml_150"> 

                  { walletState.connected && 
                      // <Button value="STAKE ALL" style={{ width: 169, height: 50, marginLeft: 28 }} onClick={() => stake(null)} dark />
                    <div className="balance-btn d_flex justify_content_center">
                      <div className="d_flex content_center align_items_center">
                        <div>
                        <p style={{lineHeight: 1}}>Balance: {arteCount.toFixed(2)}</p>
                        {(claimArtPunks.length > 0 || claimAchievements.length > 0)  && <p className='is_staked text_right' style={{lineHeight: 1}}>$ARTE</p>}
                        </div>
                      </div>

                    </div>                      
                  }
                  <WalletMultiButton className='wallet-btn'/>
                  
              </div>

              {/* <div className="total">
                  <div className="font_24">
                      TOTAL <br /> $ARTE
                  </div>
                  <div className="font_52">
                      {arteCount}
                  </div>
              </div> */}
          </div>
          {(claimArtPunks.length < 1 && claimAchievements.length < 1 ) &&
          <div className='mt_50'>
            <p>ArtPunks' staking contract was hacked on 03/31/2023 - The hacker drained the whole $ARTE staking pool and sold it. 
              The staking contract was fixed by our team to allow holders to unstake their ArtPunks, please do it as soon as possible. 
              More details on our Discord and Twitter.</p>
            <p></p>
          </div>
          }

          <div className="mt_150 pt_100 progress">
            <div className="d_flex align_items_center justify_content_between">
              <div>
                <p className='font_600'>Artpunk Staked</p>
                <p className='font_40 light_blue font_600'>{`${Number(totalStakedArtpunk / TOTAL_ARTPUNK * 100).toFixed(2)}%`}</p>
              </div>
              <div>
                <p className='font_25 light_blue font_600'>
                  { `${totalStakedArtpunk} / ${TOTAL_ARTPUNK}`}
                </p>
              </div>
            </div>
            <div className='mt_10'>
              <ProgressBar bgcolor={'#6a1b9a'} completed={totalStakedArtpunk / TOTAL_ARTPUNK * 100} />
            </div>
          </div>

          <div className="mt_20 pt_100 progress">
            <div className="d_flex align_items_center justify_content_between">
              <div>
                <p className='font_600'>Achievement Staked</p>
                <p className='font_40 light_blue font_600'>{`${Number(totalStakedAchievement / TOTAL_ACHIEVEMENT * 100).toFixed(2)}%`}</p>
              </div>
              <div>
                <p className='font_25 light_blue font_600'>
                  { `${totalStakedAchievement} / ${TOTAL_ACHIEVEMENT}`}
                </p>
              </div>
            </div>
            <div className='mt_10'>
              <ProgressBar bgcolor={'#6a1b9a'} completed={totalStakedAchievement / TOTAL_ACHIEVEMENT * 100} />
            </div>
          </div>

          <div className='mt_50 d_flex justify_content_center'>
            <div className="balance-btn d_flex justify_content_center align_items_center" onClick={stakeAll}>Stake All</div>
            <div className="claim-btn d_flex justify_content_center align_items_center" onClick={claimAll}>{`Claim All (~${arteCurrent.toFixed(2)} $ARTE)`}</div>
            <div className="balance-btn d_flex justify_content_center align_items_center" onClick={unstakeAll}>Unstake All</div>
          </div>
          <div className="nft-container">
            <div className="nft-collection">
              <div className="nft-title text_center font_600"> {`Staked Artpunks (${getLength(claimArtPunks)})`}</div> 
              <div className="mt_50 mb_20 text_center">
                {(claimArtPunks.length < 1 || claimArtPunks[0].tokenAccount === '')  &&
                  <span className='no_staked'>
                    You have no Artpunks staked, let's change that!
                  </span>
                }
              </div>
              <div className="eggs">
                {claimArtPunks.length > 2 && 
                  <Swiper
                    style={{backgroundColor: '#101922'}}
                    spaceBetween={90}
                    navigation={true} 
                    observer={true}
                    observeParents={true}
                    parallax={true}
                    pagination={{
                      type: "progressbar",
                    }}
                  slidesPerView={3}
                   // onSlideChange={() => console.log('slide change')}
                   // onSwiper={(swiper) => console.log(swiper)}
                    >
                    {claimArtPunks.map((item, index) => {
                        return (
                        <SwiperSlide key={index}>
                        <EggBox 
                        img={item.image} 
                        key={index} 
                        name={item.name}
                        type={0}
                        attribute={getAttributeName(0, item?.attribute)}
                        day = {getRewardFromAttribute(0, item?.attribute)}
                        current = {item?.current}
                        onClicks={[async () => await claim(item, 0, token), async () => await claim(item, 1, token)]}
                        values={["Claim", "Unstake"]}
                        loop={false}
                        />
                        </SwiperSlide>)        
                    })}
                  </Swiper>                
                }
                {(claimArtPunks.length !== 0 && (claimArtPunks.length < 3 && claimArtPunks[0].tokenAccount !== '')) && <>
                  {claimArtPunks.map((item, index) => {
                  return (<EggBox 
                    img={item.image} 
                    key={index} 
                    name={item.name}
                    type={0}
                    attribute={getAttributeName(0, item?.attribute)}
                    day = {getRewardFromAttribute(0, item?.attribute)}
                    current = {item?.current}
                    onClicks={[async () => await claim(item, 0, token), async () => await claim(item, 1, token)]}
                    id={index} 
                    values={["Claim", "Unstake"]}
                    loop={true}
                    /> )
                  })}        
                </>}
              </div>
            </div>
            <div className="nft-collection">
              <div className="nft-title text_center font_600">{`Artpunks Available for Staking (${getLength(artPunks)})`}</div>
              <div className="mt_50 mb_20 text_center">
                {(artPunks.length < 1 || artPunks[0].tokenAccount === '') &&
                  <span className='no_staked'>
                    You don't have any ArtPunks, you can buy them on <a className="mid_purple" href="https://www.magiceden.io/marketplace/artpunks">MagicEden</a>
                  </span>
                }
              </div>
              <div className="eggs">
                {artPunks.length > 2 && 
                  <Swiper
                    style={{backgroundColor: '#101922'}}
                    spaceBetween={90}
                    navigation={true} 
                    observer={true}
                    observeParents={true}
                    parallax={true}
                    pagination={{
                      type: "progressbar",
                    }}
                    slidesPerView={3}
                  //  onSlideChange={() => console.log('slide change')}
                  //  onSwiper={(swiper) => console.log(swiper)}
                    >
                    {artPunks.map((item, index) => {
                        return <SwiperSlide key={index}>
                          <EggBox 
                        img={item.image} 
                        key={index} 
                        name={item.name}
                        type={0}
                        attribute={getAttributeName(0, item?.attribute)}
                        day = {getRewardFromAttribute(0, item?.attribute)}
                        onClicks={[async () => await stake(item)]} id={index} 
                        values={["Stake"]}
                        loop={false}
                        />
                        </SwiperSlide>            
                    })}
                  </Swiper>                
                }
                {(artPunks.length !== 0 && (artPunks.length < 3 && artPunks[0].tokenAccount !== '')) && <>
                  {artPunks.map((item, index) => {
                  return (<EggBox 
                    img={item.image} 
                    key={index} 
                    name={item.name}
                    type={0}
                    attribute={getAttributeName(0, item?.attribute)}
                    day = {getRewardFromAttribute(0, item?.attribute)}
                    onClicks={[async () => await stake(item)]} id={index} values={["Stake"]}
                    loop={true}
                    /> )
                  })}        
                </>}
              </div>
            </div>

            <div className="nft-collection">
              <div className="nft-title text_center font_600">{`Staked Achievements (${getLength(claimAchievements)})`}</div> 
              <div className="mt_50 mb_20 text_center">
                {(claimAchievements.length < 1 || claimAchievements[0].tokenAccount === '') &&
                  <span className='no_staked'>
                    You have no Achievements staked, let's change that!
                  </span>
                }
              </div>
              <div className="eggs">
                {claimAchievements.length > 2 && 
                  <Swiper
                    style={{backgroundColor: '#101922'}}
                    spaceBetween={90}
                    navigation={true} 
                    observer={true}
                    observeParents={true}
                    parallax={true}
                    pagination={{
                      type: "progressbar",
                    }}
                    slidesPerView={3}
                  //  onSlideChange={() => console.log('slide change')}
                  //  onSwiper={(swiper) => console.log(swiper)}
                    >
                    {claimAchievements.map((item, index) => {
                        return <SwiperSlide key={index}>
                          <EggBox 
                        img={item.image} 
                        key={index} 
                        name={item.name}
                        type={1}
                        attribute={getAttributeName(1, item?.attribute)}
                        day = {getRewardFromAttribute(1, item?.attribute)}
                        current = {item?.current}
                        onClicks={[async () => await claim(item, 0, token), async () => await claim(item, 1, token)]}
                        values={["Claim", "Unstake"]}
                        loop={false}
                        />
                        </SwiperSlide>            
                    })}
                  </Swiper>                
                }
                {(claimAchievements.length !== 0 && (claimAchievements.length < 3 && claimAchievements[0].tokenAccount !== '')) && <>
                  {claimAchievements.map((item, index) => {
                  return (<EggBox 
                    img={item.image} 
                    key={index} 
                    name={item.name}
                    type={1}
                    attribute={getAttributeName(1, item?.attribute)}
                    day = {getRewardFromAttribute(1, item?.attribute)}
                    current = {item?.current}
                    onClicks={[async () => await claim(item, 0, token), async () => await claim(item, 1, token)]}
                    id={index} 
                    values={["Claim", "Unstake"]}
                    loop={true}
                    /> )
                  })}        
                </>}
              </div>
            </div>
            <div className="nft-collection">
              <div className="nft-title text_center font_600">{`Achievements Available for Staking (${getLength(achievements)})`}</div> 
              <div className="mt_50 mb_20 text_center">
                {(achievements.length < 1 || achievements[0].tokenAccount === '') &&
                  <span className='no_staked'>
                    You don't have any achievements, you can mint them <a className="mid_purple" href="https://nfthodl.zone/home">NFThodlZone</a>
                  </span>
                }
              </div>
              <div className="eggs">
                {achievements.length > 2 && 
                  <Swiper
                    style={{backgroundColor: '#101922'}}
                    spaceBetween={90}
                    navigation={true} 
                    observer={true}
                    observeParents={true}
                    parallax={true}
                    pagination={{
                      type: "progressbar",
                    }}
                    slidesPerView={3}
                  //  onSlideChange={() => console.log('slide change')}
                  //  onSwiper={(swiper) => console.log(swiper)}
                    >
                    {achievements.map((item, index) => {
                        return <SwiperSlide key={index}>
                          <EggBox 
                        img={item.image} 
                        key={index} 
                        name={item.name}
                        type={1}
                        attribute={getAttributeName(1, item?.attribute)}
                        day = {getRewardFromAttribute(1, item?.attribute)}
                        onClicks={[async () => await stake(item)]} id={index} 
                        values={["Stake"]}
                        loop={false}
                        />
                        </SwiperSlide>            
                    })}
                  </Swiper>                
                }
                {(achievements.length !== 0 && (achievements.length < 3 && achievements[0].tokenAccount !== '')) && <>
                  {achievements.map((item, index) => {
                  return (<EggBox 
                    img={item.image} 
                    key={index} 
                    name={item.name}
                    type={1}
                    attribute={getAttributeName(1, item?.attribute)}
                    day = {getRewardFromAttribute(1, item?.attribute)}
                    onClicks={[async () => await stake(item)]} id={index} values={["Stake"]}
                    loop={true}
                    /> )
                  })}        
                </>}
              </div>
            </div>
          </div>

          <Footer />
      </div>
    </div>
  )
}

export default HomePage;
