import { mainnet } from '../constants/cluster';

const CLUSTER_API = mainnet;
const CLUSTER = 'mainnet-beta';
const ARTPUNK_UPDATE_AUTHORITY = 'GtyAfQyhh2jcC3vJWEkiPzzZCK6WG3rUjgxdsdAwCx8i';
const ARTPUNK_UPDATE_AUTHORITY_2 = 'Gmo45zEiEHCwmDevVseMmqbS7XWzKREZLV1t81gKnxLL';
const ACHIEVEMENT_UPDATE_AUTHORITY = 'HR6C2p1EHXFsL1kqCpdp2EEg1WXpiMUrUJaz8k1ghod1';
const VAULT_PDA = 'BCVUUKNpqWwr5MpdiKRsXgAFruwfAWmiTpCWF7VHwkJz';
const COMMITMENT = 'confirmed';
const PROGRAM_ID = '9KTTmg9ds35Lr2w7Jbq898c9qx4rdES3Qx7EnfCNWjKu';
const ARTE_TOKEN_VAULT = 'BUMwYyuBodLgEH7WUk1xYsFiHAYXCrycNZrSBdsUyVcG';
const ARTE_TOKEN_MINT= '6Dujewcxn1qCd6rcj448SXQL9YYqTcqZCNQdCn3xJAKS';
const BACKEND_URL= 'http://18.183.136.209/api';
const TWITTER_URL = 'https://www.twitter.com/ArtPunksNFT';
const DISCORD_URL = 'https://discord.gg/artpunks';
const ARTE_VAULT_SEEDS = 'ARTE staking vault';
const POOL_ACCOUNT = '5hAXWVa9WBZEkmPXfg6Ndqk2sQhcmdd45GWex5icxXAv';
const POOL_SIGNER_SEEDS = 'POOL SIGNER';
const POOL_DATA_SEEDS = 'DATA OF ART STAKING';
const POOL_SEEDS = 'POOL';
const INTERVAL = 100000;
const TOTAL_ARTPUNK = 10000;
const TOTAL_ACHIEVEMENT = 4000;
const DECIMAL = 1000000;
const DAYTIME = 86400;
const MAX_NFT_COUNT = 12000;
const OLD_POOL_SIGNER_SEEDS = 'artpunk-achivement pool2 signer';

export {
  CLUSTER_API,
  CLUSTER,
  COMMITMENT,
  ARTPUNK_UPDATE_AUTHORITY,
  ARTPUNK_UPDATE_AUTHORITY_2,
  ACHIEVEMENT_UPDATE_AUTHORITY,
  PROGRAM_ID,
  VAULT_PDA,
  ARTE_TOKEN_VAULT,
  ARTE_TOKEN_MINT,
  BACKEND_URL,
  TWITTER_URL,
  DISCORD_URL,
  ARTE_VAULT_SEEDS,
  POOL_SIGNER_SEEDS,
  INTERVAL,
  TOTAL_ARTPUNK,
  TOTAL_ACHIEVEMENT,
  DECIMAL,
  DAYTIME,
  POOL_ACCOUNT,
  MAX_NFT_COUNT,
  POOL_SEEDS,
  POOL_DATA_SEEDS,
  OLD_POOL_SIGNER_SEEDS
}
